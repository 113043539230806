@use "@angular/material" as mat;

@include mat.core();

/* ======== Angular material custom themes ======== */

/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpalette0: (
  50: #e0f0fd,
  100: #b3dafb,
  200: #80c1f8,
  300: #4da8f5,
  400: #2696f2,
  500: #55636d,
  600: #007bee,
  700: #0070ec,
  800: #0066e9,
  900: #0053e5,
  A100: #ffffff,
  A200: #d9e4ff,
  A400: #a6c0ff,
  A700: #8caeff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-mcgpalette1: (
  50: #e8e8ef,
  100: #c6c6d7,
  200: #a0a0bd,
  300: #7a7aa2,
  400: #5e5e8e,
  500: #cea59d,
  600: #3b3b72,
  700: #323267,
  800: #2a2a5d,
  900: #1c1c4a,
  A100: #8b8bff,
  A200: #5858ff,
  A400: #2525ff,
  A700: #0c0cff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$primary-palette: mat.define-palette($md-mcgpalette0);
$accent-palette: mat.define-palette($md-mcgpalette1);

$custom-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
    ),
    typography:
      mat.define-typography-config(
        $font-family: "Tajawal",
      ),
  )
);
@include mat.all-component-themes($custom-theme);

// change in angular material

.mat-dialog-container {
  border-radius: 1.6rem !important;
}

.confirm-dialog {
  .mat-dialog-container {
    padding: 1.8rem 2.8rem 3.8rem !important;
  }
}

.logout-dialog {
  h2 {
    color: var(--dark-text-color);
    font-size: 2.4rem;
    font-weight: bold;
  }

  .mat-icon {
    rotate: 180deg;
    color: var(--accent-color) !important;
  }

  h3 {
    color: var(--dark-text-color);
    font-size: 2.1rem;
  }
}

.mat-spinner {
  margin: 2rem auto;
}

//custom tabs style
.mat-tab-group {
  box-shadow: none !important;

  .mat-tab-header {
    border-bottom: none;
  }
}

.mat-tab-label {
  background-color: transparent !important;
  opacity: 1 !important;

  &.mat-tab-disabled {
    background-color: transparent !important;

    .mat-tab-label-content {
      opacity: 1;
    }
  }

  &.mat-tab-label-active {
    background-color: transparent !important;
    opacity: 1 !important;
  }

  .mat-tab-label-content {
    color: var(--black-color);
    font-size: 16px;
  }

  &:last-of-type {
    margin-inline-start: auto;
  }
}

.mat-ink-bar {
  height: 4px !important;
  background-color: var(--accent-color) !important;
  transform: scale(0.5);
}

td.mat-cell:last-of-type {
  position: relative;
}

.mat-row:hover {
  background-color: #e8e8e8;
}

.mat-user-card {
  width: 70%;
  height: 50px;
  //background: transparent;
  margin: 0 auto;
  margin-top: 16px;
  border: none;

  & span {
    margin-left: 10px;
    line-height: 45px;
  }

  & img {
    margin-left: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    vertical-align: middle;
  }
}
.my-full-width-menu {
  width: 400px !important;
  max-width: 100% !important;
  height: 400px !important;
  max-height: 100% !important;
  padding: 8px;
}

.mat-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 1.8rem !important;

  .mat-header-cell {
    min-width: 15rem;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #f0e7e6;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 100ms cubic-bezier(0, 0, 0.2, 1);
  background-color: #f0e7e6;
}

.cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
  transition: transform 100ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-header-cell {
  padding: 0 1rem !important;
  text-align: center !important;
  border: 1px solid !important;
  border-color: var(--devider-color) !important;
}

.mat-sort-header-container {
  justify-content: center;
}

.mat-cell {
  text-align: center;
  border-bottom-color: transparent !important;
  box-shadow: 3px 3px 10px 3px #eee;
  padding: 0 1rem !important;
  white-space: nowrap;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:not(:first-of-type) {
    border-inline-start: 1.5px solid var(--devider-color);
  }
}
.overlay-snackbar {
  height: 100vh !important;
  width: 100vw !important;
  max-width: 100vw !important;
  min-width: 100vw !important;
  // background-color: $overlay;
  margin: 0 !important;
  direction: ltr;
}

.container-snackbar {
  position: absolute;
  bottom: pxToRem(56);
  left: pxToRem(56);
  background-color: $alert-color !important;
  color: $white-color !important;
  max-width: max-content !important;
  min-width: auto !important;
  text-align: center;
  border-radius: pxToRem(8);
  box-shadow: none !important;
  display: flex;
  padding: pxToRem(10) pxToRem(24);
  justify-content: flex-end;
  align-items: center;
  gap: pxToRem(10);
  @media only screen and (max-width: 768px) {
    left: 1rem;
    bottom: 5rem;
  }
}

html {
  &[dir="ltr"] {
    .container-snackbar {
      right: pxToRem(56);
      left: unset;

      @media only screen and (max-width: 768px) {
        right: 1rem;
        left: unset;
      }
    }
  }
}

.success-snackbar {
  @extend .overlay-snackbar;
  &.mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
      @extend .container-snackbar;
      background-color: $primary-color !important;
      .mdc-snackbar__label {
        direction: rtl;
        white-space: pre;
        @extend .label-medium-2;
        padding: 0 !important;
      }
    }
  }
}

.error-snackbar {
  @extend .overlay-snackbar;
  &.mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
      @extend .container-snackbar;
      .mdc-snackbar__label {
        direction: rtl;
        white-space: pre;
        @extend .label-medium-2;
        padding: 0 !important;
      }
    }
  }
}
