$primary-color: #009486;
$accent-color: #051d30;
$secondary-color: #f9bf34;
$alert-color: #ba3939;
$white-color: #fff;
$devider-color: #202042;
$box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.42);
$admin-background: #fff9f0;
$border: #ccc;
$background-light: #f9f9f9;
$overlay: rgba(15, 15, 16, 0.2);
$snackbar-bg: #323232;
$text-color: #051d30;
$icons-color: #77797b;
$shapes-color: #ddeaf3;
$lines-color: #c0c0c0;
$scrollbar-color: #e6e9d9;
$secondary-color-opacity-5: #c6ddcd;
$scroll-color: #e5e7e8;
$chat-color: #4776a7;
$border-color: #99acc4;
$font-family-ar: "Tajawal";
$font-family-en: "Poppins";

:root {
  --secondary-color: #f9bf34;
  --primary-color: #009486;
  --alert-color: #ba3939;

  --devider-color: #202042;
  --accent-color: #051d30;
}
